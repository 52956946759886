<template>
    <div>
        <highcharts v-if="loaded" :options="chartOptions" ref="chart"/>
        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import exportingInit from "highcharts/modules/exporting"
    import Highcharts from "highcharts"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    exportingInit(Highcharts)

    export default {
        name: 'StartupsStageChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: "StartupsByStage",
                chartType: "pie",
                chartOptions: {
                    chart: {
                        type: "pie",
                        options3d: {
                            enabled: true,
                            alpha: 45
                        },
                    },
                    title: {
                        text: "Startups By Stage"
                    },
                    subtitle: {
                        text: 'Additional startups: 0',
                        // align: "left",
                        verticalAlign: 'bottom',
                        // y: -2,
                        // x: 20
                    },
                    plotOptions: {
                        pie: {
                            innerSize: '50%',
                            depth: 45,
                            colors: ["#0083b2", "#44bf2e", "#ffbf1c", "#a50000"],
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.y:,.0f}'
                            },
                            events: {
                                click: (e) => {
                                    this.openStartupsModal(e.point.name)
                                }
                            }
                        },
                    },
                    xAxis: {
                        categories: [],
                    },
                    series: [
                        {
                            name: "Stage amount",
                            data: [],
                            colorByPoint: true,
                            colors: ["#0083b2", "#44bf2e", "#ffbf1c", "#a50000"]
                        }
                    ],
                    exporting: {
                        buttons: {
                            contextButton: {
                                menuItems: []
                            }
                        },
                    }
                },
                defaultMenuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
            }
        },

        watch: {
            chartType() {
                this.chartOptions.chart.type = this.chartType
            }
        },

        mounted() {
            this.chartOptions.exporting.buttons.contextButton.menuItems = [...this.defaultMenuItems, 'showColumn']
        },

        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('StartupsByStage').then(response => {

                    this.chartOptions.subtitle.text = 'Additional startups: ' + response.data.other.toLocaleString()

                    this.chartOptions.series[0].data = response.data.chartData.map(({stage, cnt}) => ([stage, cnt]))
                    this.chartOptions.xAxis.categories = response.data.chartData.map(({stage}) => stage)

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }

                    this.chartOptions.exporting.menuItemDefinitions = {
                        showPie: {
                            onclick: () => {
                                this.chartType = 'pie'
                                this.chartOptions.exporting.buttons.contextButton.menuItems = [...this.defaultMenuItems, 'showColumn']
                            },
                            text: 'Switch to Pie'
                        },

                        showColumn: {
                            onclick: () => {
                                this.chartType = 'column'
                                this.chartOptions.exporting.buttons.contextButton.menuItems = [...this.defaultMenuItems, 'showPie']
                            },
                            text: 'Switch to Bar'
                        }
                    }
                })
            },

            openStartupsModal(stage) {
                this.$emit('showStartups', [this.chartName, `Startups in "${stage}" stage`, stage])
            },
        },
    }
</script>
